import React from 'react';
import { useSelector } from 'react-redux';
import ContactModal from '../Anfrage/ContactModal';
import { markenStandortSelector } from '../../../modules/selectors/standort/markenStandort';
import Button from '../form-elements/Button';
import { ExternalEventLink, InternalLink } from '../form-elements/Link';
import { linksFooterSelector } from '../../../modules/selectors/standort/theme';
import LinkSelector from '../Header/LinkSelector';
import { CookieSettingsButton } from '../OneTrust/CookieSettingsButton';

function FooterColumns() {
    const markenStandort = useSelector(markenStandortSelector);
    const links = useSelector(linksFooterSelector);

    return (
        <section className="uk-section uk-section-secondary uk-section-small">
            <div className="uk-container">
                <div
                    className="uk-grid uk-grid-row-medium uk-grid-column-small uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-4@m"
                    data-uk-grid=""
                >
                    <div>
                        <h4 className="uk-text-uppercase">Unternehmen</h4>
                        <ul className="uk-list">
                            <li>
                                <LinkSelector to={links.ueberUns.to} type={links.ueberUns.type} label={links.ueberUns.label} />
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h4 className="uk-text-uppercase">Service</h4>
                        <ul className="uk-list">
                            <li>
                                <LinkSelector
                                    to={links.lieferungUndVersand.to}
                                    type={links.lieferungUndVersand.type}
                                    label={links.lieferungUndVersand.label}
                                />
                            </li>
                            {links.gutscheine && (
                                <li>
                                    <LinkSelector
                                        to={links.gutscheine.to}
                                        type={links.gutscheine.type}
                                        label={links.gutscheine.label}
                                    />
                                </li>
                            )}
                            
                            <li>
                                <ContactModal>
                                    {({ openModal }) => (
                                        <Button modifier="link" onClick={openModal}>
                                            Kontaktformular
                                        </Button>
                                    )}
                                </ContactModal>
                            </li>
                            {links.serviceAnfrage && (
                                <li>
                                    <LinkSelector
                                        to={links.serviceAnfrage.to}
                                        type={links.serviceAnfrage.type}
                                        label={links.serviceAnfrage.label}
                                    />
                                </li>
                            )}
                            {links.entsorgungUndUmwelt && (
                                <li>
                                    <LinkSelector
                                        to={links.entsorgungUndUmwelt.to}
                                        type={links.entsorgungUndUmwelt.type}
                                        label={links.entsorgungUndUmwelt.label}
                                    />
                                </li>
                            )}
                            
                        </ul>
                    </div>
                    <div>
                        <h4 className="uk-text-uppercase">Rechtliches</h4>
                        <ul className="uk-list">
                            <li>
                                <LinkSelector to={links.impressum.to} type={links.impressum.type} label={links.impressum.label} />
                            </li>
                            <li>
                                <LinkSelector to={links.datenschutz.to} type={links.datenschutz.type} label={links.datenschutz.label} />
                            </li>
                            <li>
                                <LinkSelector to={links.agb.to} type={links.agb.type} label={links.agb.label} />
                            </li>
                            <li>
                                <LinkSelector
                                    to={links.widerrufsrecht.to}
                                    type={links.widerrufsrecht.type}
                                    label={links.widerrufsrecht.label}
                                />
                            </li>
                            <li>
                                <InternalLink to="/copyright">Copyright</InternalLink>
                            </li>
                            <li>
                                <CookieSettingsButton />
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h4 className="uk-text-uppercase">Kontakt</h4>
                        <ul className="uk-list">
                            <li>{markenStandort.anschrift?.firma}</li>
                            <li>
                                <ExternalEventLink
                                    to={`tel:${markenStandort.anschrift?.telefon}`}
                                    action="Klick-Telefon"
                                    category="Anfrage"
                                    label={markenStandort.anschrift?.telefon}
                                >
                                    <i className="fa fa-phone" /> {markenStandort.anschrift?.telefon}
                                </ExternalEventLink>
                            </li>
                            <li>
                                <ExternalEventLink
                                    to={`mailto:${markenStandort.anschrift?.email}`}
                                    category="Anfrage"
                                    action="Klick-Email"
                                    label={markenStandort.anschrift?.email}
                                >
                                    <i className="fa fa-envelope-o" /> {markenStandort.anschrift?.email}
                                </ExternalEventLink>
                            </li>
                            <li>
                                Öffnungszeiten:
                                <br />
                                SERVICE
                                <br />
                                {markenStandort.hotline}
                                <br />
                                VERKAUF
                                <br />
                                {markenStandort.oeffnungszeiten}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FooterColumns;
